<template>
  <section v-if="articles.length" class="pt-16 lg:pt-48 relative">
    <div class="container__content space-y-8 lg:space-y-16">
      <Heading v-if="homepage.articles?.heading" class="text-center" :text="homepage.articles.heading" />
      <section class="space-y-12 lg:space-y-0">
        <ArticlesCompactArticle
          v-for="article in articles"
          :key="article.url"
          :article="article"
          :show-more-text="homepage.articles?.showMoreBtn"
          :excerptCount="25"
        />
      </section>
    </div>
  </section>
</template>
<script setup lang="ts">
const homepage = useHomepage()
const articles = useHomepageArticles()
</script>
